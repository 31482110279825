/**
 * @name: 商城管理-热销商品接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-热销商品接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IHot, IHotParams } from './types'

export const sortProductTreeApi = (params: {productName?: string}) => get('/admin/hotSaleProduct/productTree', params)

export const hotCreateApi = (data: {ids: string[]}) => postJ('/admin/hotSaleProduct/create', data)

export const hotPageApi = (params: IHotParams) => get<IPageRes<IHot[]>>('/admin/hotSaleProduct/query', params)

export const hotModifyStatusApi = (id: string) => postJ('/admin/hotSaleProduct/modifyStatus', {id})

export const hotRemoveApi = (ids: string[]) => postJ('/admin/hotSaleProduct/remove', {ids})
